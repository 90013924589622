import React, { useEffect } from 'react';
import { Box, Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import appVars from '../../appVars.json';
import Card from '@material-ui/core/Card';
import { CardActionArea, CardContent, CardHeader, CardMedia, Link, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { lightenHexColor } from '../../dwi-functions';
import ClientViewer from '../client-viewer';
var CurrencyFormat = require('react-currency-format');

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    content: {
        padding: theme.spacing(0),

    },
    media: {
        height: 'auto',
        maxWidth: 100,
        fontSize: 12,
        padding: theme.spacing(0),
        //filter: 'brightness(0) invert(1)'
    },
    pad: {
        padding: 1
    },
    logoArea: {
        background: lightenHexColor(theme.palette.primary.light, 30),
        maxHeight: 65
    }
}));

const PrimaryClient = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [clients, setClients] = useState();

    const [status, setStatus] = useState();


    async function fetchData() {

        fetch(appVars.api_url + '/clients')
            .then((response) => response.json())
            .then((res) => {
                setClients(res);
                //    console.log(res);

            }).catch((err) => console.log(err))




        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        const r = await fetch(appVars.api_url + "/status", requestOptions);
        r.json()
            .then((response) => setStatus(response))
            .then((result) => console.log(result))
            .catch((error) => console.error(error));




    }




    useEffect(() => {
        fetchData();
        console.log(status)
    }, []);



    return (

        <div className={classes.content}>
            <Grid container spacing={0} >

                <Grid item xl={12} md={12} xs={12} style={{ background: lightenHexColor(theme.palette.primary.light, 30), marginTop: theme.spacing(0), padding: theme.spacing(1) }}>

                    {(status ?
                        <Grid container spacing={0} >
                            <Grid item xl={3} md={3} >
                                <Typography align='center' variant="body2" color="textPrimary" gutterBottom>Engine Status: {status.status}...</Typography>
                            </Grid>
                            <Grid item xl={3} md={2}>
                                <Typography align='center' variant="body2" color="textPrimary" gutterBottom>Version: {status.version}</Typography>
                            </Grid>
                            <Grid item xl={3} md={2}>
                                <Typography align='center' variant="body2" color="textPrimary" gutterBottom>Secure: {status.use_ssl}</Typography>
                            </Grid>
                            <Grid item xl={3} md={5}>
                                <Typography align='center' variant="body2" color="textPrimary" gutterBottom>RunID: {status.run_id}</Typography>
                            </Grid>
                        </Grid>
                        : '')}
                </Grid>
            </Grid>

            <Grid container spacing={0}  >
                <Grid item xl={8} md={9} sm={12}  style={{ padding: theme.spacing(1) }} >
                    {(clients ?
                        <ClientViewer clients={clients}></ClientViewer>
                        :
                        <div><Typography color="primary" align='center'>Loading....</Typography><LinearProgress></LinearProgress></div>
                    )}
                </Grid>




                <Grid item xl={4} md={3} sm={12}  style={{ padding: theme.spacing(1) }}>
                    <Grid container spacing={1} direction="row-reverse" >

                        {(clients ?
                            Object.keys(clients).map((k, i, all_items) => {
                                return (


                                    <Grid xl={6} md={12} xs={12} item alignContent="stretch" >
                                        <Card variant="outlined" className={classes.root}>
                                            <CardActionArea href={'/clientview/' + clients[k].clientId}>
                                                <CardHeader
                                                    className={classes.logoArea}
                                                    title={<img className={classes.media} src={clients[k].logo} alt={clients[k].clientname} ></img>}

                                                />
                                            </CardActionArea>
                                            <CardContent style={{ padding: theme.spacing(1) }}>
                                                <Grid container spacing={1}>
                                                    <Grid item md={12} style={{ minHeight: 115 }}>
                                                        <Typography variant='body1'><Link href={'/clientview/' + clients[k].clientId} >{clients[k].clientname}</Link></Typography>
                                                        <Typography variant='body2'>{clients[k].addess}</Typography>
                                                        <Typography variant='body2'>{clients[k].city}, {clients[k].state} {clients[k].zip}</Typography>
                                                        <Typography variant='body2'>{clients[k].phone}</Typography>
                                                        <Typography variant="body2"><Link href={'/clientview/' + clients[k].email} >{clients[k].email}</Link></Typography>
                                                    </Grid>
                                                    <Grid item xl={6} md={3}>
                                                        <Box borderColor={"#d9d9d9"} border={1} borderRadius={7} bgcolor={"#d4d4d4"} p={1}>
                                                            <Typography align='center' variant="body2">Active</Typography>
                                                            <Typography align='center'><Link color="primary" href={'/clientview/' + clients[k].clientId}>{clients[k].clientCount}</Link></Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xl={6} md={3}>
                                                        <Box borderColor={"#d9d9d9"} border={1} borderRadius={7} bgcolor={"#d4d4d4"} p={1}>
                                                            <Typography align='center' variant="body2">In-active</Typography>
                                                            <Typography align='center'>{clients[k].inactiveCount}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xl={6} md={3}>
                                                        <Box borderColor={"#d9d9d9"} border={1} borderRadius={7} bgcolor={"#d4d4d4"} p={1}>
                                                            <Typography align='center' variant="body2">Convs</Typography>
                                                            <Typography align='center'>{clients[k].conversions}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xl={6} md={3}>

                                                        <Box borderColor={"#d9d9d9"} border={1} borderRadius={7} bgcolor={"#d4d4d4"} p={1}>
                                                            <Typography align='center' variant="body2">Revenue</Typography>
                                                            <Typography align='center'><CurrencyFormat prefix="$" fixedDecimalScale={true} decimalScale={2} displayType="text" value={clients[k].revenue} ></CurrencyFormat></Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                )
                            })
                            :
                            [1, 2, 3, 4, 5, 6, 7, 8].map((card, index) => (
                                <Grid xl={6} md={3} xs={12} item alignContent="stretch" >
                                    <Card>
                                        <CardHeader
                                            title={<Skeleton animation="wave" width="40%" component="h5" style={{ marginBottom: 6 }} />}
                                        >

                                        </CardHeader>
                                        <CardMedia>
                                            <Skeleton animation="wave" variant="rect" />
                                        </CardMedia>
                                        <CardContent>
                                            <Skeleton animation="wave" component="h1" width="65%" style={{ marginBottom: 6 }} />
                                            <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                            <Skeleton animation="wave" style={{ marginBottom: 6 }} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))

                        )


                        }
                    </Grid>
                </Grid>
            </Grid>

        </div >

    );
};

PrimaryClient.propTypes = {

};

export default PrimaryClient;
