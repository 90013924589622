import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    ButtonGroup,
    Grid,
    Typography,
    TextField,
    Button,
    Slider,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@material-ui/core';
import appVars from '../../appVars.json'; // Assuming appVars contains your API URLs
import { SaveOutlined, CancelOutlined } from "@material-ui/icons";
import FontSelect from '../../clients/controls/FontSelect';
import FileUploads from '../../settings/file-uploads';
import WebFont from 'webfontloader';
import { useToken } from '../../Login/TokenProvider';
import { MdPreview } from 'react-icons/md';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    imageContainer: {
        display:'flex',
        justifyContent: "flex-start",
        position: 'relative',
        padding: 0,
        width: '100%',
        maxWidth: '640px',
        margin: 'auto',
        height: 'auto',
        boxShadow: '10px 10px 40px #000000'
    },
    image: {
        maxWidth: '100%',
    },
    overlayText: {
        position: 'absolute',
        zIndex: 99999,
        margin: 0,
        padding: 0,
        width:'100%',
        height:'auto',
        lineHeight:1,
        pointerEvents: 'none', // Prevent the text from being selected
    },
    slider: {
        width: '100%',
    },
}));

const ImageEditor = (props) => {
    // console.log(props);
    var imageID = props.imageID;
    const theme = useTheme();


    const classes = useStyles();
    const [imageData, setImageData] = useState(null);
    const [fontSettings, setFontSettings] = useState({ size: '46px', color: '#ffffff', family: 'Lato-Light.ttf' });
    const [description, setDescription] = useState('');
    const [xOffset, setXOffset] = useState(0);
    const [yOffset, setYOffset] = useState(500);
    const [textAlign, setTextAlign] = useState('center');
    const [clientId, setClientId] = useState('');
    const token = useToken();
    const admintoken = token.token;
    //console.log(admintoken);
    // Fetch image data when component mounts or imageID changes
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${appVars.api_url}/imager/get/${imageID}`);
                const data = response.data[0];
                // console.log(data);
                setImageData(data);
                setDescription(data.timerDescription);
                setFontSettings(JSON.parse(data.fontSettings));
                setXOffset(data.xOffset);
                setYOffset(data.yOffset);
                setTextAlign(data.textAlign);
                setClientId(data.clientId);

                WebFont.load({
                    custom: {
                        families: [fontSettings.family.replace(/\.[^/.]+$/, "")], // The name of the font you want to load
                        urls: [`https://api.digitalwerks.com:7325/styles/imager-fonts.css`] // The URL where the font CSS is hosted
                    },
                    loading: () => {
                        console.log('Font is being loaded');
                    },
                    active: () => {
                        console.log('Font has been loaded successfully');
                    },
                    inactive: () => {
                        console.log('Font failed to load');
                    }
                });







            } catch (error) {
                console.error('Error fetching image data:', error);
            }
        };
        fetchData();
    }, [imageID]);

    const updateImage = (childdata) => {
        var f = appVars.api_url + '/imageFiles/' + props.imageID + '.jpg';
        const img = document.getElementById('preview-image');
        img.src = f;
        console.log(f);
    };


    const handleSliderChange = (event, newValue, axis) => {
        if (axis === 'x') {
            setXOffset(newValue);
        } else if (axis === 'y') {
            setYOffset(newValue);
        }
    };

    const handleFontChange = (event) => {
        setFontSettings({
            ...fontSettings,
            [event.target.name]: event.target.value,
        });
    };

    const handleSave = async () => {
        try {
            const updatedData = {
                pkRecId: imageData.pkRecId,
                timerDescription: description,
                fontSettings: JSON.stringify(fontSettings),
                xOffset,
                yOffset,
                textAlign,
                clientId,
            };
            console.log(updatedData);// return false;
            await axios.post(`${appVars.api_url}/imager/add/`, updatedData);
            props.handleclose();
            alert('Settings saved successfully!');
        } catch (error) {
            console.error('Error saving data:', error);
            alert('Failed to save settings.');
        }
    };

    return (
        <div className={classes.root}>
            <div style={{ margin: theme.spacing(0) }}>
                <Grid item sm={12} >
                    <AppBar position="relative" color="transparent" style={{ border: 'none' }} >
                        <Toolbar disableGutters >
                            <div style={{ flex: 1 }}  ></div>
                            <ButtonGroup size="large" variant="text" color="secondary">
                                <Button
                                    style={{ color: 'white', background: theme.palette.secondary.main }}
                                    href={"https://api.digitalwerks.com:7325/imager/" + imageID + "/?m=Sample Text"}
                                    target="_blank"
                                    startIcon={<MdPreview />}
                                    type="submit">
                                    Preview
                                </Button>

                                <Button
                                    style={{ color: 'white', background: 'limegreen' }}
                                    onClick={handleSave}
                                    startIcon={<SaveOutlined  ></SaveOutlined>}
                                    type="submit">
                                    Save
                                </Button>
                                <Button
                                    startIcon={<CancelOutlined></CancelOutlined>}
                                    style={{ color: 'white', background: 'red', marginRight: theme.spacing(1) }}
                                    onClick={(e) => {
                                        props.handleclose();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </Toolbar>
                    </AppBar>
                </Grid>
            </div>
            {imageData && (
                <Grid style={{ marginTop: theme.spacing(1) }} container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.imageContainer}>
                            <img id="preview-image" src={`${appVars.api_url}/imageFiles/${imageData.imageName}`} alt="Editable" className={classes.image} />
                            <Typography
                           
                                className={classes.overlayText}
                                style={{
                                    zIndex: 9999,
                                    left: xOffset,
                                    top: yOffset,
                                    fontSize: fontSettings.size,
                                    color: fontSettings.color,
                                    fontFamily: fontSettings.family.replace(/\.[^/.]+$/, ""),
                                }}
                            >
                                Sample Text
                            </Typography>

                        </div>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <form noValidate autoComplete="off">
                            <TextField
                                fullWidth
                                label="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                variant="outlined"
                                margin="normal"
                            />

                            <FormControl fullWidth variant="outlined" margin="normal">
                                <FontSelect
                                    defaultValue={fontSettings.family}
                                    onChange={handleFontChange}
                                    name="family"
                                    label="Font Family"
                                    fullWidth
                                />
                            </FormControl>

                            <TextField
                                fullWidth
                                label="Font Size"
                                name="size"
                                value={fontSettings.size}
                                onChange={handleFontChange}
                                variant="outlined"
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="Font Color"
                                name="color"
                                value={fontSettings.color}
                                onChange={handleFontChange}
                                variant="outlined"
                                margin="normal"
                            />

                            <FormControl fullWidth variant="outlined" margin="normal">
                                <InputLabel>Text Align</InputLabel>
                                <Select
                                    value={textAlign}
                                    onChange={(e) => setTextAlign(e.target.value)}
                                    label="Text Align"
                                >
                                    <MenuItem value="exact">Exact</MenuItem>
                                    <MenuItem value="center">Center</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                label="Client ID"
                                value={clientId}
                                onChange={(e) => setClientId(e.target.value)}
                                variant="outlined"
                                margin="normal"
                            />

                            <Typography gutterBottom>Horizontal Offset</Typography>
                            <Slider
                                value={xOffset}
                                valueLabelDisplay={true}
                                onChange={(e, newValue) => handleSliderChange(e, newValue, 'x')}
                                aria-labelledby="horizontal-slider"
                                min={0}
                                orientation="horizontal"
                                max={1000}
                                className={classes.slider}
                            />

                            <Typography gutterBottom>Vertical Offset</Typography>
                            <Slider
                                value={yOffset}
                                valueLabelDisplay={true}
                                onChange={(e, newValue) => handleSliderChange(e, newValue, 'y')}
                                aria-labelledby="vertical-slider"
                                min={0}
                                max={1000}
                                className={classes.slider}
                            />
                            <FileUploads admintoken={admintoken} suggest="Max Width: 650px JPG ONLY" themeColor={{ "primary": "#009ddc", "secondary": "#f26430" }} childToParent={updateImage} newid={props.imageID}></FileUploads>

                        </form>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default ImageEditor;
