import React, { useState, useEffect } from "react";
import { style } from "./index.scss";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    DialogActions,
    Typography,
    Button,
    Snackbar,
    Avatar,
    Divider,
    Paper,
    MenuItem, TextField
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import ClipboardJS from "clipboard";
import AdWords from '../../images/adwords.png';
import appVars from '../../appVars.json';
import { BsEye, BsMeta, BsBing, BsDisplay } from "react-icons/bs";
import { FaGoogle } from "react-icons/fa";
import _ from 'lodash';
import moment from 'moment';
import CookieReader from "../cookie-reader";
import { FaLongArrowAltRight } from "react-icons/fa";
import Skeleton from '@material-ui/lab/Skeleton';
import md5 from 'md5';



const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const id = getLastItem(window.location.href);

const Registrations = (props) => {


    const useStyles = makeStyles((theme) => ({
        root: {
            minWidth: 275,
        },
        table: {
            marginTop: "1em",
        },
        title: {
            borderBottom: "1px solid #000",
        },
        pos: {
            marginBottom: 12,
        },
        icon: {
            marginRight: ".25em",
            marginLeft: ".25em",
            fontSize: "13px"
        },
        paper: {
            padding: theme.spacing(1),
            background: props.themeColor.primary
        }
    }));





    const [data, setData] = useState(props);
    const classes = useStyles();
    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [pageSize, setPageSize] = useState(props.pageSize);
    const [user, setUser] = useState([]);
    const [apiStats, setApiStats] = useState({
        "client_id": "",
        "total_records": 0,
        "name_percentage": 0,
        "city_percentage": 0,
        "state_percentage": 0,
        "zip_percentage": 0,
        "phone_percentage": 0,
        "email_percentage": 0,
        "fb_percentage": 0,
        "fbc_percentage": 0,
        "ga_percentage": 0,
        "sa_percentage": 0,
        "adwords_percentage": 0,
        "bing_percentage": 0
    });
    const [open, setOpen] = useState(false);
    const [admintoken] = useState(props.admintoken.token);
    const [loading, setLoading] = useState(true);
    const [copySuccess, setCopySuccess] = useState("");




    const columns = (admintoken >= 10 ? [
        {
            title: "Entry Date", field: "createdAt", type: "datetime", render: e =>
                <Typography style={{ minWidth: 175 }}>{moment(e.createdAt).format("MM-DD-YYYY hh:mm:s")}</Typography>
        },
        { title: "pkRecID", field: "pkRecId", hidden: true, searchable: true },
        {
            title: "", field: 'pkRecId', render: (e) => {
                return (<img
                    src={(e.email ? 'https://www.gravatar.com/avatar/' + md5(e.email.trim().toLowerCase()) + '?s=200&d=mp' : <Avatar />)}
                    alt=""
                    width='100%'
                    style={{ borderRadius: '50%' }}
                />)
            }
        },
        { title: "First Name (" + (apiStats.name_percentage) + "%)", field: "first" },
        { title: "Last Name (" + apiStats.name_percentage + "%)", field: "last" },
        { title: "Email Address", field: "email" },
        { title: "Phone (" + apiStats.phone_percentage + "%)", field: "phone" },

        { title: "$", field: "revenue" },
        { hidden: true, title: "Source", field: "source" },
        {
            title: "User", field: "url", render: e => {
                var k = JSON.parse(e.cookie);
                if (k !== null) {
                    return (
                        <div style={{ minWidth: 125, display: 'inline-flex', alignItems: 'center' }}>
                            {k._fbp && (
                                <>
                                    <BsMeta
                                        className={classes.icon}
                                        title={k._fbc ? 'Meta Conversion' : 'Meta User'}
                                        style={{ color: k._fbc ? 'green' : '' }}
                                    />
                                    <FaLongArrowAltRight />
                                </>
                            )}
                            {k._ga && (
                                <>
                                    <FaGoogle className={classes.icon} />
                                    <FaLongArrowAltRight />
                                </>
                            )}
                            {k._gclid && (
                                <>
                                    <AdWords className={classes.icon} />
                                    <FaLongArrowAltRight />
                                </>
                            )}
                            {k._uetsid && (
                                <>
                                    <BsBing
                                        className={classes.icon}
                                        style={k._uetsid ? {} : { filter: 'grayscale(100%)' }}
                                    />
                                    <FaLongArrowAltRight />
                                </>
                            )}
                            {k['sa-user-id'] && (
                                <BsDisplay
                                    className={classes.icon}
                                    style={k['sa-user-id'] ? {} : { filter: 'grayscale(100%)' }}
                                />
                            )}
                        </div>
                    );
                }

            }
        },
        {
            title: "view user", field: "createdAt", type: "datetime", render: rowData =>
                <Button variant="outlined" color="primary" startIcon={<BsEye></BsEye>} onClick={(e) => {
                    setUser(rowData);
                    setOpen(true);
                }}>View</Button>
        },
        {
            title: "Location", field: "url", render: e =>
                <Typography><Link target="_blank" href={e.url}>Location</Link></Typography>
        }


    ] :
        [
            {
                title: "Entry Date", field: "createdAt", type: "datetime", render: e =>
                    <Typography style={{ minWidth: 175 }}>{moment(e.createdAt).format("MM-DD-YYYY hh:mm:s")}</Typography>
            },
            { title: "$", field: "revenue" },
            { hidden: true, title: "Source", field: "source" },
            {
                title: "User", field: "url", render: e => {
                    var k = JSON.parse(e.cookie);
                    if (k !== null) {
                        // console.log(k);
                        return <div style={{ minWidth: 125 }}>
                            {(k._fbp !== "" && k._fbp !== undefined ? <BsMeta className={classes.icon} title={(k._fbc !== '' && k._fbc !== undefined ? 'Meta Conversion' : 'Meta User')} style={{ color: (k._fbc !== '' && k._fbc !== undefined ? 'green' : '') }}></BsMeta> : '')}
                            {(k._ga !== '' && k._ga !== undefined ? <FaGoogle className={classes.icon}></FaGoogle> : '')}{(k._gclid !== '' && k._gclid !== undefined ? <AdWords></AdWords> : '')}
                            {(k._uetsid !== "" && k._uetsid !== undefined ? <BsBing className={classes.icon} style={(k._uetsid !== '' && k._uetsid !== undefined ? {} : { filter: 'grayscale(100%)' })} /> : '')}
                            {(k['sa-user-id'] !== "" && k['sa-user-id'] !== undefined ? <BsDisplay className={classes.icon} style={(k['sa-user-id'] !== '' && k['sa-user-id'] !== undefined ? {} : { filter: 'grayscale(100%)' })} /> : '')}

                        </div>
                    }
                }
            },
            {
                title: "Entry Date", field: "createdAt", type: "datetime", render: rowData =>
                    <Button variant="outlined" color="secondary" startIcon={<BsEye></BsEye>} onClick={(e) => {
                        setUser(rowData);
                        setOpen(true);
                    }}>View</Button>
            },
            {
                title: "Location", field: "url", render: e =>
                    <Typography><Link target="_blank" href={e.url}>Location</Link></Typography>
            }


        ]);










    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };

    async function fetchData() {
       // console.log(props);
        const res = await fetch(appVars.lightwerks + "/contact/" + id + "/" + pageSize);
        res
            .json()
            .then((res) => {

                var d = _.filter(res, function (o) {
                    var e = moment(o.createdAt).format("YYYY-MM-DD");
                    //  console.log(e);
                    if (e >= data.sdate && e <= data.edate) {
                        return o.createdAt;
                    }
                });
                //   console.log(d);
                setUsers(d);
                setLoading(false);
            })
            .catch((err) => setErrors(true));



        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cid": id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };




        const res2 = await fetch(appVars.lightwerks + "/apistats", requestOptions)
        res2
            .json()
            .then((res2) => {
                //   console.log(d);
                setApiStats(res2);
                setLoading(false);
            })
            .catch((err) => setErrors(true));

    }

    useEffect(() => {

        fetchData();

        setData(props);

        var clipboard = new ClipboardJS(".copy");
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });
    }, [props]);
    return (
        <div className={style}>
            <div className={classes.table}>




                <Grid container spacing={1}>
                    <Grid item sm={10}>
                        <MaterialTable
                            key={users.length}
                            isLoading={loading}
                            columns={columns}
                            data={users}
                            options={{
                                rowStyle: { fontSize: '13px', padding: 0, margin: 0 },
                                headerStyle: { fontSize: '13px' },
                                grouping: true,
                                exportButton: true,
                                exportAllData: true,
                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                pageSize: 10,
                                pageSizeOptions: [10, 25, 50, 75, 100, 200, 500, 1000],
                                showTitle: false

                            }}
                        />



                    </Grid>
                    {(admintoken >= 10 ?
                        <Grid item sm={2}>
                            <TextField
                                style={{ marginBottom: '8px' }}
                                select
                                type="number"
                                size="medium"
                                fullWidth
                                variant="outlined"
                                label="Number of records to show"
                                value={pageSize}
                                onChange={(e) => {
                                    // console.log(Number(e.target.value));
                                    setPageSize(Number(e.target.value));
                                    //   fetchData();
                                }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={500}>500</MenuItem>
                                <MenuItem value={1000}>1000</MenuItem>
                                <MenuItem value={100000000}>All</MenuItem>
                            </TextField>
                            <Paper className={classes.paper} variant="outlined">
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>Total Records:</span> {apiStats.total_records}</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>Name:</span> {parseFloat(apiStats.name_percentage).toFixed(2)}%</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>City:</span> {parseFloat(apiStats.city_percentage).toFixed(2)}%</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>State:</span> {parseFloat(apiStats.state_percentage).toFixed(2)}%</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>Phone:</span> {parseFloat(apiStats.phone_percentage).toFixed(2)}%</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>Meta User:</span> {parseFloat(apiStats.fb_percentage).toFixed(2)}%</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>Meta Clicker:</span> {parseFloat(apiStats.fbc_percentage).toFixed(2)}%</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>Google User:</span> {parseFloat(apiStats.ga_percentage).toFixed(2)}%</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>Adwords Clicker:</span> {parseFloat(apiStats.adwords_percentage).toFixed(2)}%</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>Bing User:</span> {parseFloat(apiStats.bing_percentage).toFixed(2)}%</Typography>
                                <Typography gutterBottom variant="body2" style={{ display: 'flex' }}><span style={{ flex: 1 }}>StackAdapt User:</span> {parseFloat(apiStats.sa_percentage).toFixed(2)}%</Typography>
                            </Paper>

                        </Grid>
                        : ' ')}

                </Grid>


            </div>




            <Dialog
                maxWidth="sm"
                aria-labelledby="confirmation-dialog-title"
                open={open}
            >
                {
                    <DialogTitle id="confirmation-dialog-title"></DialogTitle>
                }
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item md={3} >
                            <img
                                src={(user.email ? 'https://www.gravatar.com/avatar/' + md5(user.email.trim().toLowerCase()) + '?s=200&d=mp' : <Avatar />)}
                                alt=""
                                width='100%'
                                style={{ borderRadius: '50%' }}
                            />
                        </Grid>
                        {(admintoken >= 10 ?
                            <Grid item sm={9}>
                                <Typography>{user.first} {user.last}</Typography>
                                <Typography>{user.address}</Typography>
                                <Typography>{user.city}, {user.state} {user.zip}</Typography>
                                <Typography>{user.phone} - {user.email}</Typography>
                                <Typography>${user.revenue}</Typography>

                            </Grid>
                            : <Grid item sm={9}>
                                <Typography><Skeleton animation={false} /></Typography>
                                <Typography><Skeleton animation={false} /></Typography>
                                <Typography><Skeleton animation={false} /></Typography>
                                <Typography><Skeleton animation={false} /></Typography>
                                <Typography>${user.revenue}</Typography>
                            </Grid>
                        )}

                        <Grid item md={12}>
                            <Divider></Divider>
                            <Typography variant="h5">Cookies</Typography>
                            <CookieReader cookie={user.cookie}></CookieReader>

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" color="primary" onClick={(e) => {
                        setOpen(false);
                    }} autoFocus >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>





            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
                    <Button color="secondary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </div>

    );
};

Registrations.propTypes = {

};

export default Registrations;
