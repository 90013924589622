import React, { useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { PlainDark } from "survey-core/themes";
import { useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import appVars from '../../appVars.json';
import { Grid, Paper, Typography } from "@material-ui/core";
import BackGround from '../../pexels-thisisengineering-3862132.jpg'

const useStyles = makeStyles((theme) => ({
    bground: {
        minHeight: "100vh",
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            backgroundImage: 'url(' + BackGround + ')',
        },
        backgroundColor: '#000000',
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
    },
    rightSide: {
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(2),
            alignContent: 'flex-start',
        },
        padding: theme.spacing(10),
        paddingTop: 0,
        paddingBottom: 0,
        alignContent: 'center',
        minHeight: "100vh",
    },
    paper: {
            padding: theme.spacing(1),
            background:"#000"
    }
}));



const SurveyNPSComponent = (props) => {

    const location = useLocation();
    const id = location.pathname.split("/")[3]; // Destructure ID from URL
    const [surveyState, setSurvey] = useState(null); // Store the Model object
    const [sur, setsur] = useState(null);
    const classes = useStyles();
    // Create a new URLSearchParams object from the query string
    const queryParams = new URLSearchParams(location.search);

    // Convert the URLSearchParams into a regular object
    const paramsObj = {};
    queryParams.forEach((value, key) => {
        paramsObj[key] = value;
    });
    //console.log(paramsObj);

    // Async function to fetch data
    async function FetchData() {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        try {
            const res = await fetch(appVars.api_url + "/survey/" + id, requestOptions);
            const response = await res.json(); // Await the JSON response

            const surveyModel = new Model(JSON.parse(response[0].settings)); // Parse the survey settings
            surveyModel.onComplete.add((sender, options) => {



                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const raw = JSON.stringify({
                    "resultID": "1",
                    "surveyID": id,
                    "result": JSON.stringify(sender.data, null, 3)
                });

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                };

                fetch(appVars.api_url+"/survey/result", requestOptions)
                    .then((response) => response.text())
                    .then((result) => console.log(result))
                    .catch((error) => console.error(error));


            });

            // Apply the theme
            surveyModel.applyTheme(PlainDark);
            // Prepopulate some data for demonstration
            surveyModel.data = {
                "helpful": paramsObj.r
            };
            setsur(response[0]);
            // Set the survey model in state to trigger re-render
            setSurvey(surveyModel);
        } catch (error) {
            console.error(error);
        }
    }

    // Fetch survey data when the component mounts
    useEffect(() => {
        FetchData(); // Call the async fetch function
    }, [id]); // Make sure to re-run fetch if the ID changes

    // Render the survey component only when the model is ready
    return (
        <div className={classes.bground} >
            <Grid container alignItems="center" alignContent="center">
                <Grid item lg={6} md={0} xs={0} >
                    <div className={classes.leftSide}></div>
                </Grid>
                <Grid item lg={6} md={12} xs={12} justifyContent="center" alignContent="center" >
                    {surveyState ?
                        <div className={classes.rightSide}>
                            <Paper className={classes.paper} variant="outlined">
                                <Survey logo={appVars.api_url+"/imageFiles/"+sur.clientid+".png"} title={sur.name} model={surveyState} />
                            </Paper>
                        </div>
                        : <Typography align="center" variant="body2"> Loading survey...</Typography>}
                </Grid>
            </Grid>
        </div>
    );
};

export default SurveyNPSComponent;
