import { Box, Button, ButtonGroup, Divider, Grid, IconButton, ThemeProvider, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { lightenHexColor } from '../../dwi-functions';
import MaterialTable from 'material-table';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FcBusinessContact, FcEditImage, FcViewDetails } from "react-icons/fc";
import appVars from '../../appVars.json';
import { useTheme } from '@material-ui/core/styles';
import { NumericFormat } from 'react-number-format';
import { Link } from '@material-ui/core';
import themeFile from '../../theme.json';
import { useToken } from "../../Login/TokenProvider";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    h5: {
        borderBottom: '2px solid ' + lightenHexColor(theme.palette.primary.light, 30)
    }
}));

const ClientViewer = (props) => {

    const classes = useStyles();
    const theme = useTheme()
    const [loading, setloading] = useState(false);
    const [clCount, setCl] = useState(0);
    const token = useToken();
    const admintoken = token.token.token;
    //console.log(admintoken);

    return (
        <ThemeProvider theme={theme}>


            {(props.clients ?
                <MaterialTable
                    data={props.clients}
                    totalCount={props.clients.length}
                    options={
                        {
                            showTitle: false,
                            pageSize: props.clients.length,
                            paging: false,
                            toolbar: false,
                            header: false,
                        }
                    }
                    columns={[
                        {
                            type: 'string',
                            field: 'clientname',
                            title: 'Client Name',
                            render: rowData => <Link href={'/clientview/' + rowData.clientId}>{rowData.clientname}</Link>
                        }, {
                            type: 'numeric',
                            field: 'clientCount',
                            title: 'Client Count'
                        }
                    ]}
                    detailPanel={[
                        {
                            tooltip: 'Show Details',
                            icon: () => <FcViewDetails />,
                            openIcon: () => <IoIosCloseCircleOutline color="red" />,
                            render: rowData => {
                                return (
                                    <Grid container>

                                        <Grid style={{ background: themeFile.palette.appBar.tagmanager }} item xl={2} xs={3}>
                                            <Box color={"#ffffff"} p={1}>
                                                <img
                                                    style={{ borderRadius: theme.shape.borderRadius, padding: theme.spacing(1), maxWidth: 100, background: lightenHexColor('#000000', 80) }}
                                                    alt=""
                                                    src={rowData.logo}
                                                    onError={(e) => {
                                                        e.target.style.border = "1px dashed white";
                                                        e.target.style.borderRadius = '50%';
                                                        e.target.src = 'https://placehold.co/100x100/00a07e/FFFFFF.png?text=No+Logo&tex';
                                                    }}
                                                />
                                                <Typography variant='body1'><Link color="textSecondary" href={'/clientview/' + rowData.clientId} >{rowData.clientname}</Link></Typography>
                                                <Typography variant='body2'>{rowData.addess}</Typography>
                                                <Typography variant='body2'>{rowData.city}, {rowData.state} {rowData.zip}</Typography>
                                                <Typography variant='body2'>{rowData.phone}</Typography>
                                                <Typography variant="body2"><Link color="textSecondary" href={'/clientview/' + rowData.email} >{rowData.email}</Link></Typography>
                                                {(admintoken >= 10 ? <div><Divider style={{marginTop: 1}}/><ButtonGroup   size="small" variant="outlined" ><Button href={'/clientview/'+rowData.clientId+"?tab=3"} variant="text" startIcon={<FcEditImage />}></Button><Button href={'/clientview/'+rowData.clientId+"?tab=2"} variant="text" startIcon={<FcBusinessContact/>}></Button></ButtonGroup></div> : '')}
                                            </Box>


                                        </Grid>
                                        <Grid item xl={10} xs={9}>
                                            <MaterialTable

                                                title=""
                                                data={query =>
                                                    new Promise(async (resolve, reject) => {


                                                        var myHeaders = new Headers();
                                                        myHeaders.append("Content-Type", "application/json");

                                                        var raw = JSON.stringify({
                                                            "aid": rowData.clientId,
                                                            "type": "agency",
                                                            "date": new Date().toISOString().split('T')[0]
                                                        });

                                                        var requestOptions = {
                                                            method: 'POST',
                                                            headers: myHeaders,
                                                            body: raw,
                                                            redirect: 'follow'
                                                        };

                                                        const res = await fetch(appVars.api_url + "/report-summary", requestOptions);
                                                        //    console.log(props);
                                                        res
                                                            .json()
                                                            .then((creatives) => {
                                                                console.log(creatives.length);
                                                                setCl(creatives.length);

                                                                resolve({
                                                                    data: creatives, // your data array
                                                                    page: 0, // current page number
                                                                    totalCount: creatives.length// total row number
                                                                });


                                                            })
                                                            .catch((err) => {
                                                                console.log(err);
                                                            });

                                                    })
                                                }
                                                options={{
                                                    rowStyle: (rowData, index) => ({
                                                        background: index % 2 === 0 ?  '#fff' : lightenHexColor(theme.palette.primary.light, 30),

                                                    }),
                                                    headerStyle: {
                                                        backgroundColor: lightenHexColor(theme.palette.primary.light, 10),
                                                        color: '#ffffff',
                                                        padding: 0
                                                    },
                                                    cellStyle: {
                                                        padding: theme.spacing(1)
                                                    },
                                                    tableLayout: 'auto',
                                                    pageSize: clCount,
                                                    grouping: false,
                                                    searchFieldVariant: "outlined",
                                                    exportButton: true,
                                                    exportAllData: true,
                                                    search: false,
                                                    paging: false,
                                                    showTitle: false,
                                                    toolbar: false,
                                                    sort: false
                                                }}
                                                columns={[
                                                    { title: "Client Name", field: "id", key: 'id', hidden: true },
                                                    {
                                                        title: "Today", field: "id", render: rowData => <img
                                                            style={{ maxWidth: 100 }}
                                                            alt=""
                                                            src={`${appVars.api_url}/imageFiles/${rowData.id}.png`}
                                                            onError={(e) => {
                                                                e.target.style.border = "1px dashed white";
                                                                e.target.style.borderRadius = '50%';
                                                                e.target.src = 'https://placehold.co/100x100/00a07e/FFFFFF.png?text=No+Logo';
                                                            }}
                                                        />
                                                    },
                                                    { title: "Client Name", type:'string',field: "name", render: rowData => <Link href={'/subclientviewer/' + rowData.id}>{rowData.name}</Link> },
                                                    { title: "Transactions", type:'numeric',field: "transactions", render: rowdata => <Typography align='center'><NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.transactions} displayType="text" /></Typography> },
                                                    { title: "AVG", type:'currency',field: "AVG_Revenue", render: rowdata => <NumericFormat prefix="$" decimalScale={2} fixedDecimalScale={true} thousandSeparator="," value={rowdata.AVG_Revenue} displayType="text" /> },
                                                    { title: "Max", type:'currency',field: "MAX_Revenue", render: rowdata => <NumericFormat prefix="$" decimalScale={2} fixedDecimalScale={true} thousandSeparator="," value={rowdata.MAX_Revenue} displayType="text" /> },
                                                    { title: "Total",type:'currency', field: "Revenue", render: rowdata => <NumericFormat prefix="$"  decimalScale={2} fixedDecimalScale={true} thousandSeparator="," value={rowdata.Revenue} displayType="text" /> },
                                                ]}
                                            />
                                        </Grid>

                                    </Grid>
                                )
                            },
                        },
                    ]}
                />
                : '')}

        </ThemeProvider>

    );
};

ClientViewer.propTypes = {

};

export default ClientViewer;
