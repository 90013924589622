import { startOfToday, subDays, startOfWeek, startOfMonth, startOfQuarter, startOfYear, endOfToday, endOfWeek, endOfMonth, endOfQuarter, endOfYear } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

/**
 * Calculates the start and end dates for predefined ranges.
 * @param {string} rangeKey The key identifying the date range.
 * @returns {object} An object containing start and end Date objects.
 */
export const getPredefinedRange = (rangeKey) => {
  const today = startOfToday();
  switch (rangeKey) {
    case 'last 7 Days':
      return { start: subDays(today, 7), end: endOfToday() };
    case 'last 28 Days':
      return { start: subDays(today, 28), end: endOfToday() };
    case 'this Week':
      return { start: startOfWeek(today), end: endOfWeek(today) };
    case 'last Week':
      const lastWeekStart = subDays(startOfWeek(today), 7);
      return { start: lastWeekStart, end: subDays(endOfWeek(lastWeekStart), 1) };
    case 'Month To Date':
      return { start: startOfMonth(today), end: subDays(endOfMonth(today), 1) };
    case 'last Month':
      const lastMonthStart = subDays(startOfMonth(today), 1);
      return { start: startOfMonth(lastMonthStart), end: subDays(endOfMonth(lastMonthStart), 1) };
    case 'this Quarter':
      return { start: startOfQuarter(today), end: endOfQuarter(today) };
    case 'last Quarter':
      const lastQuarterStart = subDays(startOfQuarter(today), 1);
      return { start: startOfQuarter(lastQuarterStart), end: subDays(endOfQuarter(lastQuarterStart), 1) };
    case 'Year to Date':
      return { start: startOfYear(today), end: subDays(endOfYear(today), 1) };
    case 'Last Year':
      const lastYearStart = subDays(startOfYear(today), 1);
      return { start: startOfYear(lastYearStart), end: subDays(endOfYear(lastYearStart), 1) };
    default:
      throw new Error(`Unknown range key: ${rangeKey}`);
  }
};


export function calculatePercentageChange(firstNumber, secondNumber) {
  if (secondNumber === 0) {
      secondNumber = 1;
  }
  const difference = firstNumber - secondNumber;
  const percentageChange = (difference / secondNumber) * 100;
  return percentageChange;
}






export function generateTimestamp() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
}

export function generatePDF(reportRef, title, dateRange) {
  const input = reportRef.current;

  // Wait for all images to load
  const loadImages = (element) => {
    const images = element.getElementsByTagName('img');
    const promises = Array.from(images).map((img) => {
      return new Promise((resolve) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
          img.onerror = resolve;
        }
      });
    });
    return Promise.all(promises);
  };

  loadImages(input).then(() => {
    html2canvas(input, { scale: 1.5 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a4');

      const margin = 5;
      const pdfWidth = pdf.internal.pageSize.getWidth() - margin * 2;
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      // Add Title and Date Range
      pdf.setFontSize(12);
      pdf.text(title, margin, 10);
      pdf.text(dateRange, pdf.internal.pageSize.getWidth() - margin, 10, { align: 'right' });

      // Add the image with margins
      pdf.addImage(imgData, 'PNG', margin, 15, pdfWidth, pdfHeight, '', 'FAST');

      // Save the PDF with a smaller size
      pdf.save(title.toLowerCase() + '_' + generateTimestamp() + '.pdf');
    });
  });
}




export function getUrlParameter(name) {
  // eslint-disable-next-line  
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};



export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function darkenHexColor(hex, percent) {

  if (!hex) {
    return "#b8b8b8"
  }


  // Remove the '#' if present
  hex = hex.replace(/#/g, '');

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the darkened RGB values
  const delta = 255 * (percent / 100);
  const newR = Math.max(0, r - delta);
  const newG = Math.max(0, g - delta);
  const newB = Math.max(0, b - delta);

  // Convert darkened RGB back to hex
  const darkenedHex = `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG).toString(16).padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;

  return darkenedHex;
}


export function lightenHexColor(hex, percent) {

  if (!hex) {
    return "#b8b8b8"
  }


  // Remove the '#' if present
  hex = hex.replace(/#/g, '');

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the lightened RGB values
  const delta = 255 * (percent / 100);
  const newR = Math.min(255, r + delta);
  const newG = Math.min(255, g + delta);
  const newB = Math.min(255, b + delta);

  // Convert lightened RGB back to hex
  const lightenedHex = `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG).toString(16).padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;

  return lightenedHex;
}




export function downloadText(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function clickToCopy(input) {
  /* Get the text field */
  var copyText = document.getElementById(input);

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");
  console.log(copyText);
}


export function loadSplash(lightboxSettings) {

  //console.log(lightboxSettings);
  var dwiSplash = document.createElement('div');
  var im = JSON.parse(lightboxSettings.image);
  var link = document.createElement('link');

  // set the attributes for link element  
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = 'http://localhost:3000/a.css';

  //build markup for splash
  dwiSplash.setAttribute('class', 'dwiSplash');
  dwiSplash.innerHTML = '<div id="dl-background-splash-1"><div class=close><a id="close" onclick="sendClick(\'closed\',\'' + lightboxSettings.description + '\');">X</a></div><div class=donate-now><a href="' + lightboxSettings.url + '"onclick="sendClick(\'clicked\',\'' + lightboxSettings.description + '\');" ><img alt="' + im.imageAlt + '" title="' + im.imageAlt + '" src="' + im.imageSrc + '"/></a></div></div></div>';
  dwiSplash.appendChild(link);

  //make sure we are at the top of the page
  window.scrollTo(0, 0);

  //insert mark-up
  document.body.appendChild(dwiSplash);
  setCookie('DWI-' + lightboxSettings.lightbox_id, '1', lightboxSettings.cookie_expire);
  setTimeout(function () {
    document.getElementById("dl-background-splash-1").className += "load";
    document.getElementById('dl-background-splash-1').style["max-width"] = lightboxSettings.maxWidth;
    document.getElementById('close').style["color"] = lightboxSettings.themeColor;
  }, lightboxSettings.delay);

  window.dataLayer.push({
    'event': 'customEvent',
    'eventInfo': {
      'category': 'Lightbox Engagement',
      'action': 'Impression',
      'label': lightboxSettings.description,
      'value': 0,
      'nonInteraction': true
    }
  });
}


export function rgbToHex(rgb) {
  // Check if the input is an array of length 3 and contains valid RGB values
  if (!Array.isArray(rgb) || rgb.length !== 3 || rgb.some(val => val < 0 || val > 255)) {
    throw new Error('Invalid RGB array');
  }

  // Convert each component to a hexadecimal string
  let hex = rgb.map(color => {
    // Ensure the value is within bounds and convert to hex
    let hexValue = Math.max(0, Math.min(255, color)).toString(16);
    // Add a leading zero if necessary to ensure 2 digits
    return hexValue.length === 1 ? '0' + hexValue : hexValue;
  }).join('');

  // Return the formatted hexadecimal color code
  return '#' + hex;
}





export function resizeFont(fsize) {
  var lr = '300';
  var ur = '1366';
  var zz = (fsize / 16);
  var xx = lr / 100;
  var yy = 100 * (fsize - fsize) / (ur - lr);
  var str = 'calc(' + zz + 'rem + ((1vw - ' + xx + 'px) * ' + yy + '))';
  //console.log(str);
  return str;

}