import React, { useState, useEffect } from 'react';
import {
    TextField, LinearProgress, Grid,
    Card, CardContent, Box, Typography
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import appVars from '../../appVars.json';  // Adjust the path to your config
import {  useLocation } from 'react-router-dom';
import Logo from '../../images/gtm-logo-words.png';
const useStyles = makeStyles((theme) => ({
    formControl: { marginBottom: theme.spacing(1) },
    chip: { margin: 2, overflow: 'hidden' }
}));

const GoogleTagManagerSelect = (props) => {
    const classes = useStyles();

    const location = useLocation();
    var id = location.pathname.split("/")[2];

    const [apiKeys, setApiKeys] = useState([]);
    const [options, setOptions] = useState([]);
    const [account, setAccount] = useState([]);
    const [workspace, setWorkSpace] = useState([]);
    const [token, setToken] = useState(props.token || localStorage.getItem('idt'));

    // console.log(token);
    // Set up Axios to use Authorization header by default
    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }, [token]);

    // Fetch accounts from the backend after successful login
    async function fetchAccounts() {
        try {
            const response = await axios.post(`${appVars.api_url}/gtm/accounts`, { access_token: localStorage.getItem('google_token'), refresh_token: localStorage.getItem('rt') }, {
                withCredentials: true // Include cookies for session management
            });
            setApiKeys(response.data);  // Populate the account dropdown
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    }

    // Fetch containers based on selected account
    async function fetchContainers(accountId) {
        try {
            const response = await axios.post(`${appVars.api_url}/gtm/accounts/containers`, {
                accountId: accountId,
                access_token: localStorage.getItem('google_token'),
                refresh_token: localStorage.getItem('rt')
            }, {
                withCredentials: true // Include cookies for session management
            });
            //  setAccount({ accountId: account.accountId, containerId: '' });
            setOptions(response.data);  // Populate the container dropdown
        } catch (error) {
            console.error('Error fetching containers:', error);
        }
    }



    async function fetchWorkspace(accountId, containerId, gtm) {
        //  console.log(accountId, containerId)
        try {
            const response = await axios.post(`${appVars.api_url}/gtm/accounts/containers/workspaces`, {
                accountId: accountId,
                containerId: containerId,
                access_token: localStorage.getItem('google_token'),
                refresh_token: localStorage.getItem('rt')
            }, {
                withCredentials: true // Include cookies for session management
            });


            var settings = {
                "id": gtm,
                "account": account,
                "container": containerId,
                "workspace": response.data.workspaceId
            }
            var tag = {};
            tag.clientid = id;
            tag.tagProfile = "52e1364f-71d1-11ee-bfcd-16725369f13b";
            tag.type = "Google Tag Manager Container";
            tag.settings = JSON.stringify(settings);
 


             if (props.setGTMSettings) {
                props.setGTMSettings(settings);
            }


            setWorkSpace(response.data); 
 

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                redirect: 'follow',
                headers: myHeaders,
                body: JSON.stringify(tag)
            };

            const res = await fetch(appVars.api_url + "/tags/a", requestOptions);
            res.json()
                .then((response) => {
    
                    console.log(response);  
             
                })
                .then(result => console.log(result))
                .catch(error => console.log('error', error));




            if (props.setGTMSettings) {
                props.setGTMSettings(settings);
            }


            console.log(settings);
            setWorkSpace(response.data);  // Populate the container dropdown
        } catch (error) {
            console.error('Error fetching containers:', error);
        }
    }

    // Trigger account fetch on component mount
    useEffect(() => {
        fetchAccounts();
    }, []);

    return (
        <div className={classes.formControl}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item sm={4}>
                            <Box bgcolor={"#d7d7d7"} p={2} justifyContent="center" borderRadius={7} height="100%" alignItems="center" display="flex" >
                                <img src={Logo} alt='' />
                            </Box>
                        </Grid>
                        {!apiKeys.length ? (
                            <LinearProgress />
                        ) : (


                            <Grid item sm={8}>
                                <Autocomplete

                                    options={apiKeys}
                                    getOptionLabel={(option) => `${option.name} - ${option.accountId}`}
                                    onChange={(e, newValue) => {
                                        const accountId = newValue?.accountId;
                                        if (accountId) fetchContainers(accountId);
                                        setAccount(accountId);
                                    }}

                                    renderInput={(params) => (
                                        <TextField
                                            style={{ marginBottom: 8 }}
                                            {...params}
                                            variant="outlined"
                                            label="Select Tag Manager Account"
                                        />
                                    )}
                                />


                                {options.length ? (
                                    <Autocomplete
                                        options={options}
                                        getOptionLabel={(option) => `${option.name} - ${option.publicId}`}
                                        onChange={(e, newValue) => {
                                            const containerId = newValue?.containerId;
                                            if (containerId) fetchWorkspace(account, containerId, newValue?.publicId);

                                            //console.log(workspace);






                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Select Container"
                                            />
                                        )}
                                    />
                                ) : (
                                    <Typography></Typography>
                                )}


                                <Typography>{workspace.workspaceId} - {workspace.name}</Typography>







                            </Grid>

                        )}




                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default GoogleTagManagerSelect;
