import React, { useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import { TextField, LinearProgress, Box, ListItemText, MenuItem } from '@material-ui/core';


const FontSelect = (props) => {
    const [fonts, setFonts] = useState();
    async function fetchData() {
        const res2 = await fetch(appVars.api_url + "/fonts");
        res2
            .json()
            .then((res2) => {
                setFonts(res2);
            })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            {(fonts ?
                <Box className="hidden-xs-down" >
                    <TextField size="small" variant='outlined' shrink select {...props} {...props.field}>
                        {fonts.map((entry) => (
                            <MenuItem key={entry.pkRecID} value={entry.font_name}>
                                <Box display="flex" alignItems="center">
                                    <ListItemText primary={entry.font_family} />
                                </Box>
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                : <LinearProgress />)}
        </div>);
};

FontSelect.propTypes = {

};

export default FontSelect;
