import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography, Paper, Box, Link, makeStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, ButtonGroup, createTheme, ThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import dwLogo from '../../logo.png';
import { NumericFormat } from 'react-number-format';
import { useLocation } from 'react-router-dom';
import appVars from '../../appVars.json';
import VerticalSearch from '../controls/vertical-search';
import UserSearch from '../controls/user-search';
import { generatePDF, lightenHexColor } from "../../dwi-functions";
import _ from "lodash";
import { FcPrint } from 'react-icons/fc';
import ErrorView from '../../ErrorView';
var CurrencyFormat = require('react-currency-format');


// Custom styles


const Invoice = (props) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%', // Make it responsive
            minHeight: 'calc(100vh - 20px)',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
            },
            padding: theme.spacing(1),
            margin: 'auto', // Center the container

        },
        companyInfo: {
            padding: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`,
        },
        tableContainer: {
            marginTop: theme.spacing(2),
        },
        logo: {
            width: '100%', // Make it responsive
            [theme.breakpoints.down('sm')]: {
                width: 400,
                margin: 'auto'
            },
            height: 'auto', // Maintain aspect ratio

        },
        stats: {
            marginTop: theme.spacing(1),
            minHeight: 480
        },
        avatar: {
            width: theme.spacing(8),
            height: theme.spacing(8),
        },
        paper: {
            background: "linear-gradient(270deg, #399cdb 0%, rgba(65,133,244,1) 100%)",
            borderRadius: 7,
            border: 'solid transparent',
            color: "#ffffff"
        },
        screenshot: {
            width: '100%',
            maxWidth:200,
            margin: theme.spacing(1), // Make it responsive
            [theme.breakpoints.down('sm')]: {
                maxWidth: 400,
            },
            height: 'auto',
        },
        numbers: {
            color: theme.palette.secondary.main
        },
        footerImages: {
            width: '100%', // Make it responsive
            [theme.breakpoints.up('sm')]: {
                maxWidth: 150,
            },
            height: 'auto',
        },
        footer: {
            [theme.breakpoints.up('sm')]: {
                display: (props.showFooter ? 'flex' : 'none'),
            },

            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
            borderTop: `2px solid ${theme.palette.divider}`,

        },
        copyright: {
            textAlign: 'center',
            flexGrow: 1,
            fontSize: '0.875rem', // 14px in rem
        }
    }));


    const classes = useStyles();
    const location = useLocation();
    var id = location.pathname.split("/")[2];
    const admintoken = parseInt(props.admintoken[0]);

    //console.log(props.admintoken.token);
    const theme = createTheme({
        typography: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',  // Default Material-UI font
            h1: {
                fontFamily: '"Montserrat", sans-serif',  // Custom font for h1
            },
            body1: {
                fontFamily: '"Open Sans", sans-serif',   // Custom font for body text
            },
        },
        palette: {
            primary: {
                main: '#399cdb',
            },
            secondary: {
                main: '#e3e3e3'
            }
        },
        overrides: {
            MuiDialogTitle: {
                root: {
                    background: "linear-gradient(270deg, #399cdb 0%, rgba(65,133,244,1) 100%)",
                    color: "#ffffff"
                }
            },
            MuiToolbar: {
                root: {
                    background: "linear-gradient(270deg, #009ddc 0%, rgba(65,133,244,1) 100%)",
                    color: "#ffffff"
                }
            }

        },
        props: {
            MuiTable: {
                size: "small",
                variant: "outlined"
            },
            MuiPaper: {
                variant: "outlined"
            }
        }
    });
    const [client, setClient] = useState();
    const [tags, setTags] = useState([]);
    const [openErrors, setopenErrors] = useState();
    const [last5, setLast5] = useState();
    const [last5Dates, setLast5Dates] = useState();
    const [clientResults, setClientReults] = useState();
    const [apiStats, setApiStats] = useState({});
    const [errorlog, setErrorLog] = useState([]);


    const [loading, setLoading] = useState({
        tags: true,
        last5: true,
        last5Dates: true
    });

    const printref = useRef();

    const handleClose = () => {
        setopenErrors(false);
    };




    // Columns for the MaterialTable
    const columns = [
        {
            title: '', field: 'settings', render: (rowData) => {
                return (
                    <img
                        style={{ maxWidth: 100 }}
                        src={appVars.api_url + '/imageFiles/' + rowData.tagProfile + '.png'}
                        alt=''
                    />
                )
            }
        },
        { title: 'Name', field: 'type' },
        {
            title: 'ID', field: 'settings', render: (rowData) => {
                var a = JSON.parse(rowData.settings);

                return (
                    <Typography variant="body2">{a.id}</Typography>
                )
            }
        },
        {
            title: 'Label/ConversionID', field: 'label', render: (rowData) => {
                var a = JSON.parse(rowData.settings);

                return (
                    <Typography variant="body2">{a.conversion}</Typography>
                )
            }
        }
    ];

    function formatToLocalShortDateTime(utcDateString) {
        const utcDate = new Date(utcDateString); // Parse UTC date string
        const formatter = new Intl.DateTimeFormat(navigator.language, {
            dateStyle: 'short',
            timeStyle: 'short',
        });
        return formatter.format(utcDate); // Format to local short date & time
    }

    async function fetchData() {
        try {
            const requestOptionsGet = {
                method: 'GET',
                redirect: 'follow',
            };

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const requestOptionsPost = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({ cid: id, type: 'client' }),
                redirect: 'follow',
            };

            // Perform all requests in parallel using Promise.all
            const [tagManagerRes, tagsRes, conversionsRes, clientResultsRes, apiStatsRes, errorsRes, last5daysRes] = await Promise.all([
                fetch(appVars.api_url + `/tagmanager/${id}`),
                fetch(appVars.api_url + `/tags/${id}`, requestOptionsGet),
                fetch(appVars.api_url + `/conversions/get/${id}`, requestOptionsGet),
                fetch(appVars.api_url + `/client-results/${id}`, requestOptionsGet),
                fetch(appVars.api_url + `/apistats`, requestOptionsPost),
                fetch(appVars.api_url + `/clients/errors/${id}`, requestOptionsGet),
                fetch(appVars.api_url + `/report-summary`, requestOptionsPost),

            ]);

            // Process all responses concurrently
            const tagManagerData = await tagManagerRes.json();
            const tagsData = await tagsRes.json();
            const conversionsData = await conversionsRes.json();
            const clientReultsData = await clientResultsRes.json();
            const apiStatsData = await apiStatsRes.json();
            const errorsData = await errorsRes.json();
            const last5daysData = await last5daysRes.json();


            // Handle each response and update state
            tagManagerData.pkRecId = id;
            setClient(tagManagerData);
            document.title = `${tagManagerData.clientname}: ${tagManagerData.name}`;

            setTags(_.sortBy(tagsData, 'type'));
            setLast5(conversionsData);
            setApiStats(apiStatsData);
            setClientReults(clientReultsData);
            setErrorLog(errorsData);
            setLast5Dates(last5daysData);
            // Update loading state
            setLoading({ tags: false, last5: false, apiStats: false });
            //console.log({ tags: false, last5: false, apiStats: false });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    useEffect(() => {
        fetchData();
        return () => {
            //  console.log(client);
            //  console.log(tags)
        };
    }, []);



    // Get current year for footer
    const currentYear = new Date().getFullYear();

    return (
        <ThemeProvider theme={theme}>
            <Paper elevation={props.elevation} variant="elevation" style={{ maxWidth: props.widthS }} className={classes.root}>
                {(props.toolbar !== false ?
                    <Grid container style={{ maxWidth: props.widthS, margin: 'auto', marginBottom: theme.spacing(2) }}>
                        <Grid item md={12} >
                            <Box pt={1} pb={0} m={0} >
                                <ButtonGroup size="small">
                                    <Button startIcon={<FcPrint />} variant="contained" color="primary" title='Print Report'
                                        onClick={() => generatePDF(printref, '', '')}
                                    >Print
                                    </Button>
                                    <Button variant="contained" color="primary" title='Print Report'
                                        onClick={() => generatePDF(printref, '', '')}
                                    >GTM Install Instructions
                                    </Button>
                                    <Button variant="contained" color="primary" title='Print Report'
                                        onClick={() => generatePDF(printref, '', '')}
                                    >META Domain Verification
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Grid>
                    </Grid>
                    : '')}
                {(client ?
                    <div ref={printref} >
                        <Grid container spacing={1}>
                            {/* Row 1 - Company Info, Screenshot, Logo */}
                            <Grid item xs={12} md={5}>
                                <Box className={classes.companyInfo}>
                                    <Typography variant="h5" gutterBottom>{client.name}</Typography>
                                    <Typography variant="body1">
                                        Agency: <Link href={client.agency} target="_blank">{client.clientname}</Link>
                                    </Typography>

                                    <Typography variant="body1">
                                        Website: <Link href={client.siteUrl} target="_blank">{client.siteUrl}</Link>
                                    </Typography>
                                    <Typography variant="body1">
                                        eCommerce: <Link href={client.donationUrl} target="_blank">{client.donationUrl}</Link>
                                    </Typography>



                                    <Grid container spacing={1}  >
                                        <Grid item sm={6} alignContent="center" >
                                            <Box >
                                                <img
                                                    crossOrigin="use-credentials"
                                                    src={appVars.api_url + '/imageFiles/' + client.sitetype + '.png'} // Update with your website thumbnail path
                                                    alt="Website Thumbnail"
                                                    className={classes.screenshot}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6} alignContent="center" >
                                            <Box >

                                                <img
                                                    crossOrigin="use-credentials"
                                                    src={appVars.api_url + '/imageFiles/' + client.donationtype + '.png'} // Update with your ecommerce system logo path
                                                    alt="Ecommerce System Logo"
                                                    className={classes.screenshot}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>


                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <VerticalSearch style={{ marginBottom: theme.spacing(1) }} label="Vertical" fullWidth disabled={true} value={client.vertical} />
                                <UserSearch style={{ marginBottom: theme.spacing(1) }} label="Owner" size="small" fullWidth value={client.owner} disabled={true} />
                                <UserSearch style={{ marginBottom: theme.spacing(1) }} label="Support" size="small" fullWidth value={client.support} disabled={true} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <img
                                    crossOrigin="use-credentials"
                                    src={appVars.api_url + '/imageFiles/' + client.id + '.jpeg'} // Update with your screenshot URL or path
                                    alt="Company Logo"
                                    className={classes.logo}
                                />
                            </Grid>



                            {(apiStats ?
                                <Grid item sm={12}>
                                    <Grid container spacing={1}>
                                        <Grid item sm={3}>
                                            <Box className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Meta</Typography>
                                                <Typography align="center" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={apiStats.fbc_percentage} /></Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Box className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Google</Typography>
                                                <Typography align="center" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={apiStats.adwords_percentage} /></Typography>

                                            </Box>
                                        </Grid>

                                        <Grid item sm={3}>
                                            <Box className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Stackadapt</Typography>
                                                <Typography align="center" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={0} /></Typography>

                                            </Box>
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Box className={classes.paper}>
                                                <Typography align="center" gutterBottom variant="body1">Bing</Typography>
                                                <Typography align="center" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={0} /></Typography>

                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                : '')}


                            <Grid item sm={12}>
                                {(clientResults ?
                                    <Grid container spacing={1} >
                                        <Grid item sm={1}>

                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">Transactions: <br /><span className={classes.numbers}>{clientResults.record_count}</span></Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1} >
                                                <Typography align='center' variant="body2">Revenue: <span className={classes.numbers}><br /><CurrencyFormat prefix="$" thousandSeparator="," fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.total_revenue ?? 0)} ></CurrencyFormat></span></Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">First: <span className={classes.numbers}><br /><NumericFormat suffix="%" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.first_percentage ?? 0)} ></NumericFormat></span></Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">Last: <span className={classes.numbers}><br /><NumericFormat suffix="%" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.last_percentage ?? 0)} ></NumericFormat></span></Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">Email: <span className={classes.numbers}><br /><NumericFormat suffix="%" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.email_percentage ?? 0)} ></NumericFormat></span></Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">City: <span className={classes.numbers}><br /><NumericFormat suffix="%" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.city_percentage ?? 0)} ></NumericFormat></span></Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">State: <span className={classes.numbers}><br /><NumericFormat suffix="%" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.state_percentage ?? 0)} ></NumericFormat></span></Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">Zip: <span className={classes.numbers}><br /><NumericFormat suffix="%" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.zip_percentage ?? 0)} ></NumericFormat></span></Typography>
                                            </Box>
                                        </Grid>



                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">Source: <span className={classes.numbers}><br /><NumericFormat suffix="%" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.source_percentage ?? 0)} ></NumericFormat></span></Typography>
                                            </Box>
                                        </Grid>


                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">URL: <span className={classes.numbers}><br /><NumericFormat suffix="%" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.url_percentage ?? 0)} ></NumericFormat></span></Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item sm={1}>
                                            <Box border={1} borderColor={"#c7c7c7"} borderRadius={4} p={1}>
                                                <Typography align='center' variant="body2">IP's: <span className={classes.numbers}><br /><NumericFormat suffix="%" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(clientResults.ip_address_percentage ?? 0)} ></NumericFormat></span></Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Box color="#ffffff" border={1} borderColor={"#c7c7c7"} bgcolor={(errorlog.length === 0 ? "green" : "red")} borderRadius={4} p={1}>
                                                <Typography color='#ffffff' align='center' variant="body2">Errors<Link style={{ color: "#ffffff", cursor: "pointer" }} onClick={() => setopenErrors(openErrors => !openErrors)} ><span><br /><NumericFormat fixedDecimalScale={false} decimalScale={0} displayType="text" value={(errorlog.length ?? 0)} ></NumericFormat></span></Link></Typography>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                    : '')}
                            </Grid>



                        </Grid>



                        <Grid className={classes.stats} container spacing={1} direction="row-reverse" >
                            {/* Row 4 - Full Width Table */}
                            <Grid item sm={4} xs={12}>
                                <Paper>

                                    <Box p={1}>
                                        <Typography variant="h6">Last 5 transactions</Typography>
                                        <MaterialTable
                                            title="Last 5"
                                            totalCount={5}
                                            isLoading={loading.last5}
                                            columns={[
                                                {
                                                    title: 'Time', field: 'createdAt', render: (rowData) => {
                                                        return (
                                                            <Typography  variant="body2">{formatToLocalShortDateTime(rowData.createdAt)}</Typography>
                                                        )
                                                    }
                                                },
                                                {
                                                    title: 'Revenue', field: 'revenue', render: (rowData) => {
                                                        return (
                                                            <Typography variant="body2"><CurrencyFormat prefix="$" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(rowData.revenue ?? 0)} ></CurrencyFormat></Typography>
                                                        )
                                                    }
                                                }
                                            ]}
                                            data={last5}
                                            options={{
                                                search: false,
                                                paging: false,
                                                sorting: false,
                                                toolbar: false,
                                                header: true,
                                                cellStyle: (rowData, index) => ({
                                                    padding: 1,

                                                }),
                                                rowStyle: (rowData, index) => ({
                                                    backgroundColor: index % 2 === 0 ? lightenHexColor(theme.palette.primary.main, 70) : '#fff',
                                                }),
                                            }}
                                        />

                                    </Box>

                                </Paper>
                                <Paper style={{ marginTop: theme.spacing(1) }} >


                                    <Box p={1}>
                                        <Typography variant="h6">Daily Totals</Typography>
                                        <MaterialTable
                                            title="Last 5"
                                            totalCount={5}
                                            isLoading={loading.last5}
                                            columns={[
                                                {
                                                    title: 'Date', field: 'createdAt', render: (rowData) => {
                                                        return (
                                                            <Typography align='center' variant="body2">{rowData.DATE}</Typography>
                                                        )
                                                    }
                                                },
                                                {
                                                    title: 'Transactions', field: 'transactions', render: (rowData) => {
                                                        return (
                                                            <Typography align='center' variant="body2"><CurrencyFormat  thousandSeparator="," fixedDecimalScale={false} decimalScale={0} displayType="text" value={(rowData.transactions ?? 0)} ></CurrencyFormat></Typography>
                                                        )
                                                    }
                                                },
                                                {
                                                    title: 'Revenue', field: 'revenue', render: (rowData) => {
                                                        return (
                                                            <Typography  variant="body2"><CurrencyFormat prefix="$" thousandSeparator="," fixedDecimalScale={true} decimalScale={2} displayType="text" value={(rowData.Revenue ?? 0)} ></CurrencyFormat></Typography>
                                                        )
                                                    }
                                                },
                                                {
                                                    title: 'Largest', field: 'MAX_Revenue', render: (rowData) => {
                                                        return (
                                                            <Typography  variant="body2"><CurrencyFormat prefix="$" thousandSeparator="," fixedDecimalScale={true} decimalScale={2} displayType="text" value={(rowData.MAX_Revenue ?? 0)} ></CurrencyFormat></Typography>
                                                        )
                                                    }
                                                },
                                                {
                                                    title: 'Avg.', field: 'AVG_Revenue', render: (rowData) => {
                                                        return (
                                                            <Typography  variant="body2"><CurrencyFormat prefix="$" fixedDecimalScale={true} decimalScale={2} displayType="text" value={(rowData.AVG_Revenue ?? 0)} ></CurrencyFormat></Typography>
                                                        )
                                                    }
                                                }
                                            ]}
                                            data={last5Dates}
                                            options={{
                                                search: false,
                                                paging: false,
                                                sorting: false,
                                                toolbar: false,
                                                header: true,
                                                cellStyle: (rowData, index) => ({
                                                    padding: 1,

                                                }),
                                                rowStyle: (rowData, index) => ({
                                                    backgroundColor: index % 2 === 0 ? lightenHexColor(theme.palette.primary.main, 70) : '#fff',
                                                }),
                                            }}
                                        />
                                    </Box>

                                </Paper>
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                <MaterialTable
                                    title="Tracking Tags"
                                    isLoading={loading.tags}
                                    columns={columns}
                                    data={tags}
                                    options={{
                                        search: false,
                                        paging: false,
                                        sorting: true,
                                        showTitle: true,
                                        toolbar: false,
                                        cellStyle: (rowData, index) => ({
                                            padding: 2,

                                        }),
                                        rowStyle: (rowData, index) => ({
                                            backgroundColor: index % 2 === 0 ? lightenHexColor(theme.palette.primary.main, 70) : '#fff',

                                        }),
                                    }}
                                />
                            </Grid>

                        </Grid>




                        <Box className={classes.footer}>
                            <Box bgcolor={"#e3e3e3"} borderRadius={2} p={1} >
                                <img
                                    crossOrigin="use-credentials"
                                    src={appVars.api_url + '/imageFiles/' + client.id + '.png'} // Update with your screenshot URL or path
                                    alt={"Logo | " + client.name}
                                    className={classes.footerImages}
                                />
                            </Box>
                            <Typography className={classes.copyright}>
                                &copy; {currentYear} DigitalWerks Innovations.
                            </Typography>

                            <img
                                crossOrigin="use-credentials"
                                style={{ maxWidth: 150 }}
                                src={dwLogo} // Update with your logo URL or path
                                alt="Logo | DigitalWerks Innovations"
                                className={classes.footerImages}
                            />
                        </Box>
                        <Dialog fullWidth maxWidth="md" open={openErrors} onClose={handleClose}>
                            <DialogTitle >{"Errors"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <ErrorView admintoken={admintoken} clientid={client.id}></ErrorView>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                {(admintoken >= 10 ?
                                    <Button color="primary" variant='contained' onClick={(e) => {

                                        var myHeaders = new Headers();
                                        myHeaders.append("Content-Type", "application/json");

                                        var raw = JSON.stringify({
                                            "clientid": client.id
                                        });

                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: raw,
                                            redirect: 'follow'
                                        };

                                        fetch(appVars.api_url + "/errors", requestOptions)
                                            .then(response => fetchData())
                                            .catch(error => console.log({ show: true, message: error, severity: 'error' }))

                                    }}>Clear Errors</Button>
                                    : '')}
                                <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>


                    : '')}

            </Paper>





        </ThemeProvider>
    );
};

export default Invoice;
