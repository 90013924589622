import { React, useEffect, useState } from 'react';
import { style } from './index.scss';
import { MenuItem, TextField, IconButton, Button, Grid } from '@material-ui/core';
import appVars from '../../../appVars.json';
import { MdAddCircle } from "react-icons/md";

const MatomoSites = (props) => {

    const [sites, setSites] = useState([{ idsite: '10', name: "" }]);
    const [bdisplay, setBeaconDisplay] = useState(false);



  


    async function fetchData() {
        try {
            var data = {
                module: 'API',
                method: 'SitesManager.getAllSites',
                format: 'json', // Assuming 'json' should be lowercase
                token_auth: appVars.matomo_token
            };

            const response = await fetch(appVars.matomo_url + "/index.php", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(data).toString()
            });

            // Check if the response status is OK
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const resJson = await response.json();
            setSites(resJson);
        } catch (error) {
            console.error('Fetching Matomo data failed:', error);
        }
    }







    useEffect(() => {
        fetchData();


    if (props.value!==9 && props.value!==0) {
        setBeaconDisplay(true);
    }




    }, []);
    return (
        <div className={style}>

            <Grid container>
                <Grid item sm={1} alignContent='center'>
                    <IconButton size="small" disabled={bdisplay} color="Primary" title={'Create Beacon Site for ' + props.client.name} target="_BLANK" onClick={(e) => {
                        var _d = {
                            "method": "SitesManager.addSite",
                            "module": "API",
                            "ecommerce": 1,
                            "siteSearch": 1,
                            "currency": "USD",
                            "format": "JSON",
                            "token_auth": appVars.matomo_token,
                            "siteName": props.client.name,
                            "urls": props.client.siteUrl
                        };

                        const burl = appVars.matomo_url + "/index.php";

                        const bresponse = fetch(burl, {
                            method: 'POST', // Change to POST
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded' // Set appropriate content type
                            },
                            body: new URLSearchParams(_d).toString() // Convert the data to URL-encoded format
                        });

                        bresponse
                            .then(response => response.json()) // Parse the JSON response
                            .then(data => {
                                // Handle the response data
                                console.log(data);
                                // For example: client.beaconId = data.value;
                                // setClient(client);
                                setBeaconDisplay(true);
                            })
                            .catch(err => console.error(err)); // Handle any errors





                    }} ><MdAddCircle />
                    </IconButton>
                </Grid>
                <Grid item sm={11}>
                    <TextField

                        name="beaconId"
                        id="beaconId"
                        variant="outlined"
                        fullWidth
                        select
                        {...props}

                    >
                        <MenuItem value="0">
                            <em>Do Not Track</em>
                        </MenuItem>
                        {Object.entries(sites).map((prop, key) => {
                            //  console.log(prop[1]);
                            return <MenuItem value={prop[1].idsite}>{prop[1].idsite} - {prop[1].name}</MenuItem>
                        })
                        }
                    </TextField>
                </Grid>
            </Grid>




        </div>
    );
};

MatomoSites.propTypes = {

};

export default MatomoSites;
