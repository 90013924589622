import React, { useEffect, useState } from 'react';
import { setCookie } from '../../dwi-functions';
import { Box, LinearProgress, Typography } from '@material-ui/core';

const LogOut = () => {

    const [msg, setmsg] = useState('logging out......');

    useEffect(() => {

        setCookie("_auta_s", '', -1);
        setCookie("_au", '', -1);
        setCookie("_auta", '', -1);
        setCookie("gravatar", '', -1);
        setCookie("lwp", '', -1);
        setCookie("google_token", '', -1);
        setmsg('killing processes......');

        localStorage.removeItem("_auta_s");
        localStorage.removeItem("_au");
        localStorage.removeItem("_auta");
        localStorage.removeItem("gravatar");
        localStorage.removeItem("lwp");
        localStorage.removeItem("google_token");
        setmsg('killing variables......');

        sessionStorage.removeItem("_auta_s");
        sessionStorage.removeItem("_au");
        sessionStorage.removeItem("_auta");
        sessionStorage.removeItem("gravatar");
        sessionStorage.removeItem("lwp");
        sessionStorage.removeItem("google_token");
        setmsg('killing session......');

        setmsg('Redirecting to Login');
        window.location.href = "/";




        return () => {

        };
    }, []);





    return <Box pr={8} pl={8} pt={4} pb={4} alignContent="center" style={{ height: '60vh' }} ><Typography align='center' gutterBottom variant="h4">{msg}<LinearProgress></LinearProgress></Typography></Box>;
};

LogOut.propTypes = {

};

export default LogOut;
