import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import { Link, Typography, Snackbar, CircularProgress } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import MaterialTable from 'material-table';
import appVars from '../appVars.json';
import _ from 'lodash';

const ErrorView = (props) => {
    const [loading, setLoading] = useState(true);
    const [errorlog, setErrorLog] = useState([]);
    const [error, setError] = useState({ show: false, message: 'Test', severity: 'error' });
    const [progress, setProgress] = useState(false);


    console.log(props);



    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError({ show: false, message: '', severity: 'success' });
    };

    async function fetchData() {
        const res5 = await fetch(appVars.api_url + "/clients/errors/"+props.clientid);
        res5
            .json()
            .then((res5) => {
                var a = res5;

                //console.log(a.length);
                if (props.clientid !== undefined) {
                    a = _.filter(res5, ["clientid", props.clientid]);
                }

                setErrorLog(a);
                setProgress(true);
                setLoading(false);
            })
            .catch((err) => setError({ show: true, message: err.message, severity: 'error' }));

    }

    useEffect(() => {
        fetchData();
    }, [props]);

    return (
        <div className={style}>




            {(errorlog ?
                <MaterialTable
                  
                    isLoading={loading}
                    columns={[
                        { title: "", type: "datetime", field: "createdAt", defaultSort: 'desc', cellStyle: { padding: "3px 10px" }, hidden: true },
                        {
                            title: "Name", field: "name", defaultSort: 'asc', cellStyle: { padding: "3px 10px" }
                            , render: rowData =>
                                <Typography style={{ fontSize: '13px' }}><Link href={'/subclientviewer/' + rowData.clientid}>{(rowData.name.length > 50 ? rowData.name.substring(0, 50) : rowData.name)} ...</Link></Typography>
                        },
                        {
                            title: "Error", field: "error_message", defaultSort: 'desc', cellStyle: { padding: "3px 10px" }, render: rowData =>
                                <Typography style={{ fontSize: '13px' }}>{(rowData.error_message.length > 75 ? rowData.error_message.substring(0, 75) : rowData.error_message)} ...</Typography>
                        }
                    ]}
                    data={errorlog}
                    options={{
                        rowStyle: { fontSize: '13px', padding: 0, margin: 0 },
                        headerStyle: { fontSize: '13px' },
                        search: true,
                        grouping: false,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20, 25, 40, 50, 100],
                        showTitle: true,
                        paging: true,
                        header: false,
                        toolbar:false

                    }}
                />
                : <CircularProgress hidden={progress} ></CircularProgress>
            )}








            <Snackbar open={error.status} autoHideDuration={3500} onClose={handleClose}>
                <Alert severity={error.severity} onClose={handleClose}>
                    {error.msg}
                </Alert>
            </Snackbar>

        </div>
    );
};

ErrorView.propTypes = {

};

export default ErrorView;
