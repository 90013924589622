import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import AdWords from '../../images/adwords-and-icon.png';
import StackAdapt from '../../images/stackadapt.png';
import GoogleA from '../../images/GA4-768x768.png';
import Google360 from '../../images/ga360-1024x177.png';
import Google from '../../images/g1.0.png';
import Logo from '../../images/matomo-logo.png';
import Facebook from '../../images/Facebook-Logo-700x394.png';
import Bing from '../../images//textbing-.png';
import CrazyEgg from '../../images/crazy-egg.png';
import Stackify from '../../images/stackify.png';
import { makeStyles } from "@material-ui/core/styles";
import { BsEye, BsMeta, BsBing, BsDisplay,BsGoogle } from "react-icons/bs";
import { SiGoogleanalytics,SiGoogleads } from "react-icons/si";
import { GrAnalytics } from "react-icons/gr";
import { TbView360Number } from "react-icons/tb";

const useStyles = makeStyles({
    images: {
        maxWidth: 100
    }
});





const CookieReader = (props) => {
    var c = JSON.parse(props.cookie);
    const classes = useStyles();
    console.log(c)
   // console.log(Object(c));

    return (
        <div>
            <Grid container spacing={3} alignItems='center'>


                {Object.entries(c).map((entry, key) => {
                    var k = '';
                    switch (entry[0]) {
                        case "_ga": // Google Analytics
                            k = <Grid item md={1}><SiGoogleanalytics className={classes.images} color={(c['_uman'] !== '' ? 'green' :  'gray' )} alt={entry[1]}/></Grid>
                            break;
                        case "_gid": // Google 360
                            k = <Grid item md={1}><TbView360Number className={classes.images} color={(c['_uman'] !== '' ? 'green' : 'gray' )} src={Google360} alt={entry[1]}/></Grid>
                            break;
                        case "_fbp": //Meta Pixel
                        
                            k = <Grid item md={1}><BsMeta color={(c['_fbc'] !== '' && c['_fbc'] !== undefined ? 'green ': 'grey' )} className={classes.images}  alt={entry[1]}/></Grid>
                            break;
                        case "_gcl_au": //Conversion Linker / AdWords
                          //  k = <Grid item md={1}><img style={(c['gclid'] !== '' && c['gclid'] !== undefined ? {} : { filter: 'grayscale(100%)' })} src={AdWords} alt={entry[1]}></img></Grid>
                            break;
                        case "gclid": //Conversion Linker / AdWords
                            k = <Grid item md={1}><SiGoogleads color={(c['gclid'].length <= 1 ? 'green' : 'gray' )} alt={entry[1]}/></Grid>
                            break;
                        case "sa-user-id": //stack Adapt
                            k = <Grid item md={1}><BsDisplay color={(c['_saclid'] !== '' && c['_saclid'] !== undefined ? 'green' : 'grey')}  alt={entry[1]} /></Grid>
                            break;

                        case "_uetuid":
                            k = <Grid item md={1}><BsBing color={(c['_uetmsclkid'] ? 'green' : 'gray' )} className={classes.images} alt={entry[1]}/></Grid>
                            break;
                        default:



                            if (entry[0].indexOf('_pk') >= 0) {
                                k = <Grid item md={1}><GrAnalytics color={(c['_pkid'] ? 'green' : 'gray' )} className={classes.images} src={Logo} alt={entry[1]}/></Grid>
                            }

                            if (entry[0].indexOf('_ce') >= 0) {
                                //k = <Grid item md={1}><img className={classes.images} src={CrazyEgg} alt={entry[1]}></img></Grid>
                                k = ' ';
                            }

                            if (entry[0].indexOf('Stackify') >= 0) {
                                k = <Grid item md={1}><img className={classes.images} src={Stackify} alt={entry[1]}></img></Grid>
                            }

                            if (entry[0].indexOf('fundraiseup') >= 0) {
                                k = ' ';
                            }

                            if (entry[0].indexOf('_gcl_aw') >= 0 || entry[0].indexOf('_ga') >= 0) {
                                k = <Grid item md={1}><BsGoogle style={(c['_gclkid'] ? 'green': 'gray' )} className={classes.images} src={Google} alt={entry[1]}/></Grid>
                            }

                            if (entry[0].indexOf('visit') >= 0) {
                                k = ' ';
                            }


                            if (entry[0].indexOf('_ga_') >= 0 || entry[0].indexOf('_gat_') >= 0) {
                                k = ' ';
                            }

                            if (entry[0].indexOf('_fbc') >= 0 || entry[0].indexOf('_gat_') >= 0) {
                                k = ' ';
                            }

                            if (entry[0].indexOf('_gac_UA') >= 0 || entry[0].indexOf('_gat_') >= 0) {
                                k = ' ';
                            }

                            if (entry[0].indexOf('ceb') >= 0 || entry[0].indexOf('_gat_') >= 0) {
                                k = ' ';
                            }

                            if (k === '') {
                                //  k = <span>{entry[0]}</span>
                            }

                            break;
                    }
                    return (

                        <Fragment>{k}</Fragment>
                    )
                })}
            </Grid>
        </div>
    );
};

CookieReader.propTypes = {

};

export default CookieReader;
