import React, { useEffect } from 'react';
import {  useLocation } from 'react-router-dom';
import appVars from '../../appVars.json';
import { setCookie } from '../../dwi-functions';
import { jwtDecode } from "jwt-decode";
import { useHistory } from 'react-router-dom';
import { Box, LinearProgress, Typography } from '@material-ui/core';


const SuccessPage = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    console.log(jwtDecode(searchParams.get("idt")));

 useEffect(() => {
        // Here, you could make a request to check the session on the backend
        const checkSession = async () => {
            try {

                    localStorage.setItem('google_token', searchParams.get("at"));
                    localStorage.setItem('lwp', jwtDecode(searchParams.get("idt")));

                    const response = await fetch(appVars.api_url + '/validate', {
                        method: 'POST',
                        body: JSON.stringify({ token: searchParams.get("at"), email: jwtDecode(searchParams.get("idt")).email }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    response.json()
                        .then(data => {
                            if (data.status === 200 && data.uid.length === 36) {
                                setCookie('_au', data.uid, 7);
                                localStorage.setItem('_au', data.uid);
                                localStorage.setItem('gravatar', data.gravatar);
                                setCookie('gravatar', data.gravatar, 7);
                                localStorage.setItem('_auta', JSON.stringify(data.admin.access));
                                setCookie('_auta', data.admin.access, 7);
                                localStorage.setItem('_auta_s', JSON.stringify(data.settings));
                                setCookie('_auta_s', JSON.stringify(data.settings), 7);
                                localStorage.setItem('idt', searchParams.get("idt"));
                                localStorage.setItem('lwp', JSON.stringify(jwtDecode(searchParams.get("idt"))));
                                localStorage.setItem('rt', JSON.stringify(searchParams.get("rt")));
                                window.location.href="/";
                            }
                        });

            } catch (error) {
                console.error('Error verifying session:', error);
            }
        };
        checkSession();
    }, [history]);

    return <Box pr={8} pl={8} pt={4} pb={4} alignContent="center" style={{height:'60vh'}} ><Typography align='center' gutterBottom  variant="h4">Loading....<LinearProgress></LinearProgress></Typography></Box>;
};

export default SuccessPage;
