import React, { useState, useEffect } from 'react';
import { Grid, Tab, Tabs, Box, Typography, Snackbar, Badge } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import MainMenu from '../DashBoard/MainMenu';
import appVars from '../appVars.json';
import { makeStyles } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import themeFile from '../theme.json';
import PropTypes from 'prop-types';
import PrimaryClient from './primary-client';
import { Check, Error, Money, ViewAgendaOutlined } from '@material-ui/icons';
import ErrorView from '../ErrorView';
import Navbar from '../Navbar';

var _ = require('lodash');


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}





function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    h4: {
        marginBottom: theme.spacing(2)
    },
    paper: {
        padding: '1em',
        margin: '1em 0',
        background: "transparent",
        textAlign: "center",
        color: "white"
    },
    paperError: {
        background: themeFile.palette.feedback.error.background,
        textAlign: "center",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        paddingTop: ".5em"
    },
    paperSuccess: {
        background: themeFile.palette.feedback.success.background,
        textAlign: "center",
        marginBottom: "1em",
        padding: "0em"
    },
    revenue: {
        backgroundColor: themeFile.palette.secondary.main,
        padding: "1em",
        color: "white",
        borderRadius: "7px"
    },
    appBar: {
        background: themeFile.palette.primary.main,
        color: '#fff',
        padding: '0!important'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        height: '65px',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0, 0),
        // marginLeft: "75px"
    },
}));

const DashBoard = (props) => {

    //const classes = useStyles();
    const classes = useStyles();
    const [error, setErrors] = useState({ status: !1, msg: "No Error!", severity: "error" });
    const [loading, setLoading] = useState(true);
    const [errorcount, setErrorCount] = useState(0);
    const [conversions, setConverions] = useState([]);
    const [refreshInterval, setRefreshInterval] = useState(10000); // Adjust the interval as needed (in milliseconds)
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    async function fetchData() {


        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const dd = String(today.getDate()).padStart(2, '0');

        const formattedDate = `${yyyy}-${mm}-${dd}`;
        console.log(formattedDate);

    }






    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={classes.revenue}>
                    <Typography variant='h6'>{`${label}`}</Typography>
                    <Typography variant='body2'>
                        <span>{`Conversions: ${payload[0].value}`}</span><br></br>
                        <span>{`Revenue: $${payload[0].payload['revenue']}`}</span></Typography>
                </div>
            );
        }

        return null;
    };


    useEffect(() => {
         fetchData();
        document.title = "Main Dashboard";
        //   const intervalId = setInterval(fetchStats, refreshInterval);
        //  return () => clearInterval(intervalId);
    }, [refreshInterval]);




    return (
        <div>
            <div className={classes.toolbar} />
            <div className={classes.root}>
                <Navbar siteTitle={'Lightwerks'}></Navbar>

                <Grid container spacing={0} direction="row-reverse" >
                    <Grid item xs={12} md={12} >
                        <Box sx={{ marginTop: "0em", marginBottom: theme.spacing(0), borderBottom: 1, borderColor: 'divider' }} className={classes.appBar}>
                            <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="basic tabs example" >
                                <Tab icon={<ViewAgendaOutlined></ViewAgendaOutlined>} label="Overview" {...a11yProps(0)} />
                                <Tab icon={<Money></Money>} label="Revenue" {...a11yProps(1)} />
                                <Tab icon={<Check></Check>} label="Conversions" {...a11yProps(2)} />
                                <Tab icon={<Error></Error>} label={<Badge badgeContent={errorcount} color="secondary">Errors</Badge>} {...a11yProps(3)} />
                            </Tabs>
                        </Box>

                        <TabPanel value={value} index={0} data-tab="overview">
                    
                                    <PrimaryClient ></PrimaryClient>
                
                        </TabPanel>

                        <TabPanel value={value} index={1} data-tab="revenue">
                            <Grid item xs={12}>

                                <BarChart
                                    style={{ background: "transparent" }}
                                    width='100%'
                                    height={600}
                                    data={conversions}
                                    margin={{
                                        top: 25,
                                        right: 25,
                                        left: 25,
                                        bottom: 25,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis domain={[0, 7500]} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Bar dataKey="revenue" label fill={themeFile.palette.secondary.main} />
                                </BarChart>

                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={2} data-tab="conversions">

                            <Grid container xs={12} spacing={1}>
                                <Grid item xs={12}>



                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={3} data-tab="Errors">
                            <Grid container xs={12} spacing={1}>
                                <Grid item xs={12}>
                                    <ErrorView></ErrorView>
                                </Grid>
                            </Grid>
                        </TabPanel>


                    </Grid>


                </Grid>
                <Snackbar open={error.status} autoHideDuration={3500} >
                    <Alert severity={error.severity}>
                        {error.msg}
                    </Alert>
                </Snackbar>
                <MainMenu></MainMenu>
            </div>
        </div>
    );
};

DashBoard.propTypes = {

};

export default DashBoard;
